import React, {useRef} from 'react';
import {LanguageProvider} from 'hook/LanguageContext';
import {GlobalProvider} from 'hook/GlobalContext';
import useWindowSize from 'hook/useWindowSize';
import BannerMessage from 'components/bannerMessage';
import Footer from 'components/footer';
import Header from 'components/header';
import * as styles from './fourOhFour.module.scss';

const MID_POINT_BREAK = 768;
const PL_NUMBER = 'PL015900.A';
const DEFAULT_LIVONGO_NUMBER = '1-800-945-4355';
const LIVONGO_BUSINESS_LINK =
    'https://teladochealth.com/contact/?utm_source=lvgo';
const SIGN_UP_LINK = 'https://signup.livongo.com';

const DOES_NOT_EXIST =
    "The page you're looking for doesn't exist or may have moved.";
const HERE_ARE_A_FEW_LINKS_THAT_MAY_BE_HELPFUL =
    'Here are a few links that may be helpful:';

const FourOhFour = () => {
    const bannerRef = useRef(null);
    const {width} = useWindowSize();
    const isMobile = width < MID_POINT_BREAK;

    return (
        <GlobalProvider>
            <LanguageProvider availableLanguages={[]}>
                <BannerMessage ref={bannerRef} />
                <main
                    role="main"
                    style={{
                        paddingTop: bannerRef.current // Add additional padding at the top to make room for the banner
                            ? bannerRef.current.clientHeight
                            : 0,
                    }}
                >
                    <div className={styles.container}>
                        <Header
                            contactNumber={DEFAULT_LIVONGO_NUMBER}
                            isMobile={isMobile}
                        />
                        <div className={styles.title}>404</div>
                        <div className={styles.pageMissing}>
                            {DOES_NOT_EXIST}
                        </div>
                        <div className={styles.hereSomeLinks}>
                            {HERE_ARE_A_FEW_LINKS_THAT_MAY_BE_HELPFUL}
                        </div>
                        <div>
                            <a href="/" className={styles.link}>
                                Home
                            </a>
                        </div>
                        <div>
                            <a
                                href={LIVONGO_BUSINESS_LINK}
                                className={styles.link}
                            >
                                Livongo for Business
                            </a>
                        </div>
                        <div>
                            <a href={SIGN_UP_LINK} className={styles.link}>
                                Join Livongo
                            </a>
                        </div>
                    </div>
                </main>
                <Footer plNumber={PL_NUMBER} />
            </LanguageProvider>
        </GlobalProvider>
    );
};

export default FourOhFour;
